<template>
  <div>
      <h1>Admin</h1>

  </div>
</template>

<script>

import { collections, getDocs, query, orderBy, db, collection } from "@/firebase/firebase.js";


export default {
  name: "Admin",

  components: {
    /*test,*/
  },
  data: function () {
    return {
      Personen: [
        { id: 0, Name: "Christoph", Alter: 30 },
        { id: 1, Name: "Dennis", Alter: 50 },
      ],
      links: [],
    };
  },
  methods: {
    getName(Person) {
      return Person.Name;
    },
  },
};
</script>
